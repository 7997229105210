<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h6>Breakdown By Gender</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover" id="table-2">
                            <thead>
                                <tr>
                                    <th>Gender</th>
                                    <th>Total</th>
                                    <th>(%)</th>
                                </tr>
                            </thead>
                            <tbody v-if="data.length == 0">
                                <tr class="text-center">
                                    <td colspan="3">
                                        <Spinner />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr v-for="(gender, index) in uniqueGender" :key="index">
                                    <td>{{ gender ? $globalFunc.capitalizeFirstLetter(gender) : '' }}</td>
                                    <td>{{ getCount(gender) }}</td>
                                    <td>{{ getCountPercentage(gender) }}%</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="data.length > 0">
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{{ data.length }}</strong></td>
                                    <td><strong>100%</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '@/components/layouts/Spinner';
export default {
    name: "SortByGender",
    props: {
        data: {
            type: Array,
            required: true
        },
    },    
    components: {
        Spinner
    },
    computed: {
        uniqueGender() {
            return this.data.length > 0 ? [...new Set(this.data.map(item => item.customer_gender))] : [];
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        getCount(gender) {
            return this.data.filter(item => item.customer_gender == gender).length;
        },
        getCountPercentage(gender) {
            return ((this.getCount(gender) / this.data.length) * 100).toFixed(2);
        },
    },
}
</script>
<style lang="">
</style>