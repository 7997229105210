<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h6>Breakdown by Responsible User</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover" id="table-1">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Total</th>
                                    <th>(%)</th>
                                </tr>
                            </thead>
                            <tbody v-if="data.length == 0">
                                <tr class="text-center">
                                    <td colspan="3">
                                        <Spinner />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(name, index) in uniqueResponsisbleUser" :key="index">
                                    <td>{{ name }}</td>
                                    <td>{{ getCount(name) }}</td>
                                    <td>{{ getCountPercentage(name) }}%</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="data.length > 0">
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{{ data.length }}</strong></td>
                                    <td><strong>100%</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '@/components/layouts/Spinner';
export default {
    name: "SortByResponsibleUser",
    props: {
        data: {
            type: Array,
            required: true
        },
    },
    components: {
        Spinner
    },
    computed: {
        uniqueResponsisbleUser() {
            return this.data.length > 0 ? [...new Set(this.data.map(item => item.responsible_user))] : [];
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        getCount(responsible_user) {
            return this.data.filter(item => item.responsible_user == responsible_user).length;
        },
        getCountPercentage(responsible_user) {
            return ((this.getCount(responsible_user) / this.data.length) * 100).toFixed(2);
        },
    },
}
</script>
<style lang="">
</style>