import store from "../../store";
import router from "../../router";
import ReportService from "../../services/ReportService";

export default {
  uploadReportToday(reports) {
    store.dispatch("setLoading", true);
    const response = ReportService.uploadReportToday(reports)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  getTodaysReport() {
    store.dispatch("setLoading", true);
    const response = ReportService.getTodaysReport()
      .then((result) => {
        const { status, data: { payload } } = result.data
        if (status) {
          return { status: true, payload };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  }
};
