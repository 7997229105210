<template>
  <section>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h3>Angaza report</h3>
          </div>
          <div class="card-body">
            <form @submit.prevent="" class="d-flex justify-content-between align-items-center">
              <div class="form-group">
                <label for="file">File</label>
                <input
                  type="file"
                  class="form-control-file"
                  id="file"
                  name="file"
                  @change="handleFileUpload"
                  accept=".xls, .xlsx"
                />
              </div>
              <button class="btn btn-primary btn-md" @click.prevent="uploadData()" :disabled="loading" v-if="user.email == 'engchris95@gmail.com' || user.email == 'kim.danladi@asolarnig.com'">{{ loading ? 'Uploading' : 'Upload File' }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <sort-by-status :data="reports" :ctx="graphContext"></sort-by-status>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-4">
        <sort-by-state :data="reports"></sort-by-state>
        <sort-by-gender :data="reports"></sort-by-gender>
      </div>
      <div class="col-md-4">
        <sort-by-responsible-user :data="reports"></sort-by-responsible-user>
      </div>
      <div class="col-md-4">
        <sort-by-system :data="reports"></sort-by-system>
        <sort-by-product :data="reports"></sort-by-product>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div
              class="row justify-content-between align-items-center"
              v-if="reports.length > 0"
            >
              <div class="col-md-3">
                <div class="form-group">
                  <label for="responsibleUser">Responsible Users</label>
                  <select
                    v-model="filter.responsible_user"
                    class="form-control"
                    @change="updateRecord"
                  >
                    <!-- <option value="all">All</option> -->
                    <option
                      :value="item"
                      v-for="(item, index) in uniqueResponsibleUsers"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-primary float-right"
                  @click.prevent="exportData"
                  :disabled="exporting"
                  v-if="sortedData.length > 0"
                >
                  {{ exporting ? "Exporting" : "Export" }}
                </button>
              </div>
            </div>
            <div
              class="row justify-content-between align-items-center"
              v-if="sortedData.length > 0"
            >
              <div class="col-md-12">
                <h6 class="">
                  <strong>Total: </strong>{{ sortedData.length }}
                </h6>
              </div>
            </div>
            <div class="loading" v-if="reports.length == 0">
              <Spinner />
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th v-for="(header, index) in headers" :key="index">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, jindex) in sortedData.length" :key="jindex">
                    <td
                      v-for="(data, jindex) in sortedData[item - 1]"
                      :key="jindex"
                    >
                      {{ data }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ReportHelpers from "@/functions/actions/ReportRequest";
import XlsExport from "xlsexport";
import SortByState from "@/components/report/daily/SortByState";
import SortByResponsibleUser from "@/components/report/daily/SortByResponsibleUser";
import SortBySystem from "@/components/report/daily/SortBySystem";
import SortByProduct from "@/components/report/daily/SortByProduct";
import SortByGender from "@/components/report/daily/SortByGender";
import SortByStatus from "@/components/report/daily/SortByStatus";
import Spinner from "@/components/layouts/Spinner";
import { mapState } from 'vuex';
export default {
  name: "AngazaDailyReport",
  computed: {
    ...mapState(["loading", "user"]),
    uniqueResponsibleUsers() {
      return this.reports.length > 0
        ? [...new Set(this.reports.map((item) => item.responsible_user))]
        : [];
    },
  },
  components: {
    SortByState,
    SortByResponsibleUser,
    SortBySystem,
    SortByProduct,
    SortByGender,
    SortByStatus,
    Spinner
  },
  data() {
    return {
      headers: [],
      reports: [],
      sortedData: [],
      filter: {
        responsible_user: "",
      },
      todaysrecord: false,
      exporting: false,
      graphContext: {}
    };
  },
  mounted() {
    var ctx = document.getElementById("myChart4").getContext("2d");
    this.graphContext = ctx
    this.getTodaysReport();
  },
  methods: {
    exportData() {
      this.exporting = true;
      const filename = `accounts${new Date().toISOString().slice(0, 10)}.xls`;
      var xls = new XlsExport(this.sortedData);
      xls.exportToXLS(filename);
      this.exporting = false;
    },
    updateRecord() {
      if (this.filter.responsible_user == "all") {
        this.sortedData = this.reports;
      } else {
        this.sortedData = this.reports.filter(
          (item) => item.responsible_user == this.filter.responsible_user
        );
      }
    },
    async uploadData() {
      const response = await ReportHelpers.uploadReportToday(this.reports);
      if (response.status) {
        this.showAlert("Success", "Report Uploaded successfully", "success")
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async getTodaysReport() {
      const response = await ReportHelpers.getTodaysReport();
      if (response.status) {
        this.todaysrecord = true
        this.reports = response.payload;
        this.headers = this.formatHeadersProperly(Object.keys(response.payload[0]));
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (event) => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.headers = Object.keys(rowObject[0]);
            const headers = this.formatHeaders(Object.keys(rowObject[0]));
            const data = this.formatData(rowObject, headers);
            this.reports = data;
          });
        };
      } else {
        alert("File not found");
      }
    },
    formatHeaders(headers) {
      return headers.map((header) => this.formatSlug(header));
    },
    formatHeadersProperly(headers) {
      return headers.map((header) => this.formatSlugProperly(header));
    },
    formatSlug(str) {
      return str.replace(" ", "_").toLowerCase();
    },
    formatSlugProperly(str) {
      return this.$globalFunc.capitalizeFirstLetter(str.replace("_", " "));
    },
    formatData(payload, headers) {
      const data = [];
      for (let i = 0; i < payload.length; i++) {
        const row = payload[i];
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = row[this.headers[j]];
        }
        data.push(obj);
      }
      return data;
    },
  },
  showAlert(title, text, type) {
    this.$fire({
      title,
      text,
      type: type ? type : "warning",
    });
  },
};
</script>
<style lang="">
</style>