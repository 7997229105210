<template>
  <div class="card">
    <div class="card-header">
      <h6>Breakdown By Account Status</h6>
    </div>
    <div class="card-body">
      <Spinner v-if="data.length == 0" />
      <canvas id="myChart4" ref="myChart4"></canvas>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/layouts/Spinner";
export default {
  name: "SortByStatus",
  props: {
    data: {
      type: Array,
      required: true,
    },
    ctx: {
      type: [Object, Function, CanvasRenderingContext2D],
      required: true,
    },
  },
  components: {
    Spinner,
  },
  computed: {
    uniqueStatus() {
      return this.data.length > 0
        ? [...new Set(this.data.map((account) => account.account_status))]
        : [];
    },
  },
  data() {
    return {};
  },
  watch: {
    data() {
      this.data ? this.createPieChart() : "";
    },
  },
  methods: {
    createPieChart() {
      new Chart(this.ctx, {
        type: "doughnut",
        data: {
          labels: this.uniqueStatus,
          datasets: [
            {
              label: "My First Dataset",
              data: this.getCount(),
              backgroundColor: this.backgroundColor()
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            position: 'bottom',
          },
        }
      });
    },
    backgroundColor() {
      var data = this.uniqueStatus.map((item) => {
        let code = ''
        switch (item.toLowerCase()) {
          case 'disabled':
            code = 'rgb(255, 0, 0)'
            break;
          case 'enabled':
            code = 'rgb(0, 0, 255)'
            break;
          case 'unlocked':
            code = 'rgb(0 135 87)'
            break;
          case 'detached':
            code = 'rgb(254, 206, 1)'
            break;
          default:
            code = 'rgb(255, 255, 255)'
            break;
        }
        return code;
      })
      return data
    },
    getCount() {
      var data = this.uniqueStatus.map((item) => {
        return this.data.filter((account) => account.account_status == item)
          .length;
      });
      return data;
    },
  },
};
</script>
<style lang="">
</style>