<template>
    <img src="assets/img/spinner.svg" alt="spinner" style="width: 3rem">
</template>
<script>
export default {
    name: 'Spinner',
}
</script>
<style lang="">
    
</style>