<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h6>Breakdown By States</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover" id="table-1">
                            <thead>
                                <tr>
                                    <th>States</th>
                                    <th>Total</th>
                                    <th>(%)</th>
                                </tr>
                            </thead>
                            <tbody v-if="data.length == 0">
                                <tr class="text-center">
                                    <td colspan="3">
                                        <Spinner />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr v-for="(state, index) in uniqueStates" :key="index">
                                    <td>{{ state }}</td>
                                    <td>{{ getCount(state) }}</td>
                                    <td>{{ getCountPercentage(state) }}%</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="data.length > 0">
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{{ data.length }}</strong></td>
                                    <td><strong>100%</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '@/components/layouts/Spinner';
export default {
    name: "SortByState",
    props: {
        data: {
            type: Array,
            required: true
        },
    },    
    components: {
        Spinner
    },
    computed: {
        uniqueStates() {
            return this.data.length > 0 ? [...new Set(this.data.map(item => item.client_state))] : [];
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        getCount(state) {
            return this.data.filter(item => item.client_state == state).length;
        },
        getCountPercentage(state) {
            return ((this.getCount(state) / this.data.length) * 100).toFixed(2);
        },
    },
}
</script>
<style lang="">
</style>